import Vue from 'vue';
import Vuex from 'vuex';
import localization from './modules/localization';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  modules: {
    localization,
  },
  strict: debug,
});
