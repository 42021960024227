<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>

<script>

import { mapActions } from 'vuex';
import Locale from './mixins/locales';

export default {
  name: 'App',
  mixins: [Locale],
  data() {
    return {
      headerLoaded: false,
      headerData: null,
    };
  },
  computed: {
    loaded() {
      return (this.headerLoaded);
    },
  },
  methods: {
    ...mapActions('localization', [
      'init',
      'setLanguage',
    ]),
  },
  created() {
    this.init();
  },
};
</script>
