import { extend, localize } from 'vee-validate';
import en from 'vee-validate/dist/locale/en.json';
import de from 'vee-validate/dist/locale/de.json';
import * as rules from 'vee-validate/dist/rules';

// install rules and localization
Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});

localize('en', en);
localize('de', de);

extend('required', {
  validate(value) {
    return {
      required: true,
      valid: ['', 'null', null, undefined, false].indexOf(value) === -1,
    };
  },
  computesRequired: true,
});

extend('countryCode', {
  validate: (value) => {
    const stripped = value.replace(/ /g, ''); // remove whitespace
    const regexp = /^(00|\+)/;
    return !!regexp.test(stripped);
  },
  message: 'Please enter the country code.',
});

extend('numericPhone', {
  validate: (value) => {
    const stripped = value.replace(/ /g, ''); // remove whitespace
    const regexp = /.{8,20}$/;

    return !!regexp.test(stripped);
  },
  message: 'Please enter a number between 8 and 20 characters.',
});

extend('numberDec', {
  validate: (value) => {
    const regexp = /^(\d+)?([.]?\d{0,1})?$/;
    return !!regexp.test(value);
  },
  message: 'Please enter a valid value',
});

extend('atLeastOne', {
  validate: (value) => value.length > 0,
  message: 'Please select at least one option',
});
