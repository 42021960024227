<template>
  <div class="container referral-view__footer mt-5">
    <div class="row justify-content-center mb-2">
      <span class="label referral-view__label">{{ __('footer_partners_title') }}</span>
    </div>
    <div class="row justify-content-center">
      <div class="col-12 col-sm-4">
        <div class="row justify-content-between">
          <div class="col-4 d-flex align-items-center">
            <img src="../../assets/logo/intersport-logo.png" class="w-100" alt="20 Minuten">
          </div>
          <div class="col-4 d-flex align-items-center">
            <img src="../../assets/logo/swisspass-logo.svg" class="w-100" alt="Höhle der Löwen">
          </div>
          <div class="col-4 d-flex align-items-center">
            <img src="../../assets/logo/suva-logo.svg" class="w-100" alt="TV24">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import locales from '@/mixins/locales';

export default {
  name: 'footer-partners',
  mixins: [locales],
};
</script>
