<template>
  <div class="container referral-view__footer mt-5">
    <div class="row justify-content-center mb-2">
      <span class="label referral-view__label">{{ __('footer_community_title') }}</span>
    </div>
    <div class="row flex-column align-items-center">
      <h1>10 000+</h1>
      <h5>App Downloads</h5>
    </div>
  </div>
</template>
<script>
import locales from '@/mixins/locales';

export default {
  name: 'footer-community',
  mixins: [locales],
};
</script>
