import Vue from 'vue';
import VueRouter from 'vue-router';

import './config/sentry';

import firebase from 'firebase/app';
import 'firebase/remote-config';

import { ValidationObserver, ValidationProvider } from 'vee-validate';
import './config/vee-validate';

import router from './router';
import store from './store';

import App from './App';

import './styles/app.scss';

Vue.config.productionTip = false;
Vue.use(VueRouter);

Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);

/*
load remote config
 */
firebase.initializeApp({
  apiKey: 'AIzaSyCYNKm5jcwqR6TdYQhLrkQrEj6cFmIp_9Q',
  authDomain: 'hyll-web-referral.firebaseapp.com',
  projectId: 'hyll-web-referral',
  storageBucket: 'hyll-web-referral.appspot.com',
  messagingSenderId: '564366222211',
  appId: '1:564366222211:web:f840d0484708b1ec488cb6',
  measurementId: 'G-7XLDS58FZ3',
});

const remoteConfig = firebase.remoteConfig();
remoteConfig.settings = {
  minimumFetchIntervalMillis: 10,
  fetchTimeoutMillis: 15000,
};

remoteConfig.fetchAndActivate().then(() => {
  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount('#app');
});
