import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('localization', [
      'language',
      'languageLabel',
      'locales',
    ]),
    __() {
      return (key, variables) => {
        let value = '';
        if (this.locales) {
          if (this.locales[key]) {
            value = this.locales[key];
          } else {
            value = key;
          }
        }

        if (variables) {
          Object.keys(variables).forEach((varKey) => {
            value = value.replace(new RegExp(`:${varKey}`, 'g'), variables[varKey]);
          });
        }

        return value;
      };
    },
    __has() {
      return (key) => this.locales && this.locales[key];
    },
    __language() {
      return this.language;
    },
    __languageLabel() {
      return this.languageLabel;
    },
  },
};
