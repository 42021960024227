import Vue from 'vue';
import Router from 'vue-router';

import ReferralView from './views/ReferralView';

Vue.use(Router);

let boothCode;
let linkType;

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/:code?',
      name: 'referral-view',
      component: ReferralView,
    },
  ],
});

router.beforeEach(() => {
  const code = window.location.pathname.substring(1);
  if (code) {
    const codeParts = code.split('_');
    boothCode = codeParts[0];
    linkType = codeParts[1];
  }
  window.location.href = `https://hyll.com/welcome-gift/\
    ?utm_campaign=affiliate-partner&booth_code=${boothCode}&utm_source=${linkType}&utm_medium=marketing`;
});

export default router;
