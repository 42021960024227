import Vue from 'vue';
import store from '../store';

const initSentry = async () => {
  const Sentry = await import('@sentry/browser' /* webpackChunkName: "sentry" */);
  const { Integrations } = await import('@sentry/tracing' /* webpackChunkName: "sentry" */);

  Sentry.init({
    Vue,
    release: process.env.VUE_APP_COMMIT_SHA,
    version: process.env.VUE_APP_COMMIT_SHA,
    environment: process.env.VUE_APP_SENTRY_ENV,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    autoSessionTracking: true,
    integrations: [
      new Integrations.BrowserTracing(),
    ],
    tracesSampleRate: 0.2,
  });

  // Add vuex state to sentry scope
  Sentry.configureScope((scope) => {
    scope.setContext('state', {
      state: {
        type: 'Vuex',
        value: JSON.stringify(store.state),
      },
    });
  });
};

if (process.env.VUE_APP_LOG_SENTRY) initSentry();
