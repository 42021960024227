/* eslint no-shadow: ["error", { "allow": ["state"] }] */

import firebase from 'firebase/app';
import 'firebase/remote-config';

import * as types from '../mutation-types';

const AVAILABLE_LANGUAGES = [
  { key: 'en', label: 'English' },
  { key: 'de', label: 'Deutsch' },
  { key: 'fr', label: 'Francais' },
];

// initial state
const state = {
  availableLanguages: [],
  language: null,
  locales: {},
};

// getters
const getters = {
  availableLanguages: (state) => state.availableLanguages,
  language: (state) => state.language,
  languageLabel: (state) => {
    const language = state.availableLanguages.find((l) => l.key === state.language);
    if (language) {
      return language.label;
    }
    return '';
  },
  locales: (state) => {
    const locales = {};
    Object.keys(state.locales).forEach((locale) => {
      locales[locale] = state.locales[locale][state.language];
    });
    return locales;
  },
};

// actions
const actions = {
  init({ commit, dispatch }) {
    commit(types.LOCALIZATION_SET_AVAILABLE_LANGUAGES, AVAILABLE_LANGUAGES);

    let userLanguage = (navigator.language || navigator.userLanguage).substring(0, 2);
    // Fall back to english if no user language is not available
    if (!state.availableLanguages.find((l) => l.key === userLanguage)) userLanguage = 'en';

    dispatch('setLanguage', userLanguage);
    dispatch('loadLanguageRemoteConfig');
  },
  setLanguage({ commit }, language) {
    commit(types.LOCALIZATION_SET_LANGUAGE, language);
  },
  loadLanguageRemoteConfig({ commit }) {
    const remoteConfig = firebase.remoteConfig();
    const locales = remoteConfig.getValue('locales')._value;
    commit(types.LOCALIZATION_SET_LOCALES, JSON.parse(locales));
  },
};

// mutations
const mutations = {
  [types.LOCALIZATION_SET_AVAILABLE_LANGUAGES](state, availableLanguages) {
    state.availableLanguages = availableLanguages;
  },
  [types.LOCALIZATION_SET_LANGUAGE](state, language) {
    state.language = language;
  },
  [types.LOCALIZATION_SET_LOCALES](state, locales) {
    state.locales = locales;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
