<template>
  <div class="referral-view">
    <div class="content">
      <div class="referral-view__hero">
        <div class="referral-view__logo"></div>
      </div>

      <div class="container mt-4">
        <div v-if="showCheckin">
          <div class="row">
            <div class="col-12 d-flex flex-column align-items-center">
              <div class="referral-view__tagline" v-html="__('checkin')"></div>
            </div>
          </div>
        </div>

        <div v-if="showPhoneNumber">
          <div class="row">
            <div class="col-12 d-flex flex-column align-items-center">
              <h1 class="referral-view__title" v-html="title"></h1>
              <div class="referral-view__tagline" v-html="tagline"></div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="d-flex flex-column flex-sm-row justify-content-center">
                <div class="referral-view__input-container">
                  <label class="label referral-view__label" for="phone">{{__('phone_number')}}</label>

                  <div class="d-flex align-items-start">
                    <div class="referral-view__country-code select">
                      <select name="mobileNumberPrefix"
                              id="mobileNumberPrefix"
                              class="select__input"
                              v-model="mobileNumberPrefix">
                        <option v-for="prefix in phonePrefixes" :value="prefix.value" :key="prefix.label">{{prefix.label}}</option>
                      </select>
                    </div>

                    <div class="referral-view__phone-number">
                      <input-mask id="phone"
                                  type="tel"
                                  class="input referral-view__input"
                                  mask="(0)99 999 99 99 99" mask-char=" "
                                  v-model="mobileNumber"/>
                    </div>
                  </div>
                  <span v-if="error" class="input__error">{{ __('error_phone_number') }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="showSuccess">
          <div class="row">
            <div class="col-12 d-flex flex-column align-items-center">
              <div class="referral-view__tagline" v-html="tagline"></div>
            </div>
          </div>
        </div>

        <div class="row justify-content-center">
          <div class="col-12 col-lg-4 d-flex justify-content-center">
            <button class="button referral-view__button" @click="submit" :disabled="!phoneUtil">{{button}}</button>
          </div>
        </div>
      </div>
    </div>

    <component
        v-if="footerComponent"
        :is="footerComponent"
    ></component>
  </div>
</template>

<script>
import 'core-js/modules/es.string.replace-all';
import firebase from 'firebase/app';
import InputMask from 'vue-input-mask';
import 'firebase/remote-config';
import FooterMedia from '@/views/footer/FooterMedia';
import FooterPartners from '@/views/footer/FooterPartners';
import FooterCommunity from '@/views/footer/FooterCommunity';
import locales from '../mixins/locales';
import tracking from '../mixins/tracking';
import HTTP from '../plugins/http';

const randomValue = (items) => items[Math.round(Math.random() * (items.length - 1))];

const footerComponents = [
  FooterMedia,
  FooterPartners,
  FooterCommunity,
];

export default {
  name: 'ReferralView',
  mixins: [locales, tracking],
  components: {
    InputMask,
  },
  data() {
    return {
      remoteConfig: null,
      phoneUtil: null,
      code: null,
      boothCode: null,
      booth: null,
      linkType: null,
      mobileNumberPrefix: '+41',
      mobileNumber: '',
      success: false,
      error: false,
      footerComponent: null,
    };
  },
  computed: {
    phone() {
      return `${this.mobileNumberPrefix}${this.mobileNumber.replaceAll(' ', '').substring(3)}`;
    },
    title() {
      return (this.__has(`title_${this.linkType}`)) ? this.__(`title_${this.linkType}`) : this.__('title');
    },
    tagline() {
      let tagline = this.__('tagline', { booth: this.booth });
      if (this.__has(`tagline_${this.linkType}`)) {
        tagline = this.__(`tagline_${this.linkType}`, { booth: this.booth });
      }
      if (this.showSuccess) {
        if (this.__has(`success_text_${this.linkType}`)) {
          tagline = this.__(`success_text_${this.linkType}`, { booth: this.booth });
        } else {
          tagline = this.__('success_text', { booth: this.booth });
        }
      }
      if (!this.booth) {
        tagline = this.__('tagline_unknown');
      }
      return tagline;
    },
    link() {
      let link = this.remoteConfig.getValue('success_link')._value;
      const typedLink = this.remoteConfig.getValue(`success_link_${this.linkType}`)._value;
      if (typedLink) {
        link = typedLink;
      }
      if (this.linkType === 'ch') {
        link = this.remoteConfig.getValue('link_checkin')._value;
      }
      if (!this.booth) {
        link = this.remoteConfig.getValue('link_unknown_code')._value;
      }
      // replace placholders
      link = link.replace(':booth_code', this.boothCode);
      return link;
    },
    button() {
      let button = this.__('confirm_button_text');
      if (this.__has(`confirm_button_text_${this.linkType}`)) {
        button = this.__(`confirm_button_text_${this.linkType}`);
      }
      if (this.showSuccess) {
        if (this.__has(`success_button_text_${this.linkType}`)) {
          button = this.__(`success_button_text_${this.linkType}`);
        } else {
          button = this.__('success_button_text');
        }
      }
      if (this.showCheckin) {
        button = this.__('button_checkin');
      }
      return button;
    },
    showCheckin() {
      return this.linkType === 'ch';
    },
    showPhoneNumber() {
      return !this.showCheckin && !this.success;
    },
    showSuccess() {
      return !this.showCheckin && this.success;
    },
    phonePrefixes() {
      if (this.phoneUtil) {
        return this.phoneUtil.getSupportedRegions().map((region) => {
          const prefix = this.phoneUtil.getCountryCodeForRegion(region);
          return {
            label: `${region} +${prefix}`,
            value: `+${prefix}`,
          };
        });
      }
      return [];
    },
  },
  watch: {
    mobileNumber() {
      this.error = false;
    },
  },
  methods: {
    loadBooth() {
      const booths = JSON.parse(this.remoteConfig.getValue('booths')._value);
      this.booth = (booths && booths[this.boothCode]) ? booths[this.boothCode] : null;
    },
    submit() {
      if (this.showCheckin) {
        this.track('checkin_clicked_get_app', {
          complete_code: this.code,
          phone_number: this.phone,
        });
        this.redirect(this.link);
      } else if (this.showPhoneNumber) {
        this.track('clicked_finish_referral', {
          complete_code: this.code,
          phone_number: this.phone,
        });
        this.join();
      } else {
        this.track('referral_clicked_get_app', {
          complete_code: this.code,
          phone_number: this.phone,
        });
        this.redirect(this.link);
      }
    },
    redirect() {
      window.location = this.link;
    },
    redirectEmptyCode() {
      window.location = this.remoteConfig.getValue('link_empty_code')._value;
    },
    async join() {
      try {
        await HTTP.post('/join/', {
          booth_code: this.boothCode,
          type: this.linkType,
          raw_code: this.code,
          phone: this.phone,
        }, {
          headers: {
            'Content-Language': this.__language,
          },
        });

        this.success = true;
        window.scrollTo(0, 0);

        this.track('reached_referral_success', {
          complete_code: this.code,
          phone_number: this.phone,
          footer_type: this.footerComponent.name,
        });
        this.facebookTrackEvent('Lead', {
          code: this.code,
        });
      } catch (e) {
        this.error = e;
      }
    },
  },
  async created() {
    // Import libphonenumber
    const { PhoneNumberUtil } = await import('google-libphonenumber' /* webpackChunkName: "libphonenumber" */);
    this.phoneUtil = PhoneNumberUtil.getInstance();

    // Load remote config
    this.remoteConfig = firebase.remoteConfig();

    // Choose random footer
    this.footerComponent = randomValue(footerComponents);

    this.code = this.$route.params.code;
    if (this.code) {
      const codeParts = this.code.split('_');

      this.boothCode = codeParts[0];
      this.linkType = codeParts[1];

      this.loadBooth();

      await this.track('reached_referral_form', {
        complete_code: this.code,
        footer_type: this.footerComponent.name,
      });
    } else {
      this.redirectEmptyCode();
    }
  },
};
</script>
