import api from '../api/tracking';

export default {
  methods: {
    track: api.track,
    facebookTrackEvent(event, data) {
      if (window.fbq) {
        window.fbq('track', event, data);
      }
    },
  },
};
